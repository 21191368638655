import { Component, OnInit } from '@angular/core';
import { PlayerDataService, HydratedPlayer } from 'src/app/services/data/player-data/player-data.service';
import { PromptService } from 'src/app/services/prompt/prompt.service';
import { DataService, UpdateType, Season } from 'src/app/services/data/data.service';
import { Endpoints, HttpService } from 'src/app/services/http.service';
import { HydratedTeam, TeamDataService } from 'src/app/services/data/team-data/team-data.service';

@Component({
  selector: 'app-league-admin-view',
  templateUrl: './league-admin-view.component.html',
  styleUrls: ['./league-admin-view.component.scss']
})
export class LeagueAdminViewComponent implements OnInit {
  constructor() { }

  async ngOnInit() {
    
  }

}
