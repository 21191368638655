import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import 'firebase/firestore';
import { AnalyticsService } from '../analytics/analytics.service';
import { firestore } from 'firebase';


enum Roles {
  admin = 'admin',
  teamOwner = 'teamOwner',
  player = 'player',
}

/**
 * MongoDB style query syntax
 * {
 *   name: 'John Doe',
 *   age: 5,
 * }
 */
export interface DataQuery {
  [field: string]: any;
}

// Export data types here
export enum UpdateType {
  Create,
  Update,
  Delete,
}

export enum TimeControl {
  Blitz = 'Blitz',
  Bullet = 'Bullet',
}

export interface AbstractData {
  id: string;
}

export interface LiveStreamData {
  game_id: string;
  id: string;
  language: string;
  started_at: string;
  tag_ids: string[];
  thumbnail_url: string;
  title: string;
  type: 'stream' | '';
  user_id: string;
  user_name: string;
  viewer_count: number;
}

export interface Streamer {
  liveStream: LiveStreamData|null;
  username: string;
}

export interface TVData {
  liveStream: LiveStreamData;
  nblStreamTag: string;
  streamers: Streamer[];
}

export interface ArticleTemplateData {
  leadingScorer: string;
  leadingScorerPoints: number;
  winningTeamName: string;
  winningTeamTotalPoints: number;
  winningTeamId: string;
  tournamentTimeControl: 'Blitz' | 'Bullet';
}

export interface NewsArticle {
  id: string; // Maps to tournamentID
  content: string;
  image?: string;
  data: ArticleTemplateData;
  startTime: string;
}

export interface Season {
  id: string;
  tournaments: string[];
  teams: string[];
  startDate: string;
  numWeeks: number;
}

export interface Tournament {
  seasonId: string;
  timeControl: TimeControl;
  results: Stats[];
  teamResults?: Map<string, number>;
}

export interface Team {
  id: string;
  name: string;
  players: string[];
  active: boolean;
  numPenalties: number;
}

export interface Stats {
  blitzStats: {
    numGames: number;
    points: number;
    ppg: number;
  };
  bulletStats: {
    numGames: number;
    points: number;
    ppg: number;
  };
}
export interface Player extends AbstractData {
  username: string;
  name: string;
  team: string;
  seasons: {[seasonId: string]: Stats};
  authId?: string;
  role?: {[key in Roles]: boolean};
}

export interface GenericUpdate {
  documentQuery: string;
  type: UpdateType;
}
export interface Update extends GenericUpdate {
  update: any;
  type: UpdateType.Update;
}

export interface Delete extends GenericUpdate {
  type: UpdateType.Delete;
}

export interface Create extends GenericUpdate {
  type: UpdateType.Create;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private _afs: AngularFirestore, private _analytics: AnalyticsService) {}
  public arrayUnion = firestore.FieldValue.arrayUnion;
  public arrayRemove = firestore.FieldValue.arrayRemove;

  getCollection<T>(collectionName: string): Promise<Array<T>> {
    const promise = new Promise((resolve, reject) => {
      try {
        const sub = this._afs.collection(collectionName).get()
          .pipe(take(1))
          .pipe(map(snapshot => snapshot.docs.map(doc => {
            const data = doc.data() as T;
            const id = doc.id;
            return { id, ...data };
          })))
          .subscribe(value => {
            sub.unsubscribe();
            resolve(value);
          });
      } catch (err) {
        reject(err);
      }
    });
    return promise as Promise<Array<T>>;
  }

  async getCollectionAsMap<T>(collectionName: string): Promise<Map<string, T>> {
    const collection = await this.getCollection<T>(collectionName);
    const collectionAsMap = new Map();
    for(const item of collection) {
      collectionAsMap.set((item as unknown as AbstractData).id, item);
    }
    return collectionAsMap as unknown as Promise<Map<string, T>>;
  }

  async find<T>(collectionName: string, query: DataQuery) {
    const promise = new Promise((resolve, reject) => {
      try {
        const collection = this._afs.collection(collectionName, ref => {
          let collectionRef: CollectionReference |  Query = ref;
          for (const [field, value] of Object.entries(query)) {
            collectionRef = collectionRef.where(field, '==', value);
          }
          collectionRef = collectionRef.limit(1);
          return collectionRef;
        });
        const sub = collection.get()
          .pipe(take(1))
          .pipe(map(snapshot => snapshot.docs.map(doc => {
            const data = doc.data() as T;
            const id = doc.id;
            return { id, ...data };
          })))
          .subscribe(values => {
            sub.unsubscribe();
            resolve(values[0]);
          });
      } catch (err) {
        reject(err);
      }
    });
    return promise as Promise<T>;
  }

  subscribeToCollection<T>(collectionName: string): Observable<Array<T>> {
    return this._afs.collection(collectionName).valueChanges() as Observable<Array<T>>;
  }

  addToCollection(collectionName: string, item: any, id?: string) {
    if (id) {
      return this._afs.collection(collectionName).doc(id).set(item);
    } else {
      return this._afs.collection(collectionName).add(item);
    }
  }

  getDocument<T>(documentQuery: string) {
    const promise = new Promise((resolve, reject) => {
      try {
        const sub = this._afs.doc(documentQuery).get()
          .pipe(take(1))
          .subscribe(value => {
            sub.unsubscribe();
            if(!value.data()) {
              resolve(null);
            } else {
              resolve({
                id: value.id,
                ...value.data(),
              });
            }
          });
      } catch (err) {
        reject(err);
      }
    });
    return promise as Promise<T>;
  }

  updateDocument(documentQuery: string, update: any) {
    return this._afs.doc(documentQuery).update(update);
  }

  setDocument(documentQuery: string, value: any) {
    return this._afs.doc(documentQuery).set(value);
  }

  deleteDocument(documentQuery: string) {
    return this._afs.doc(documentQuery).delete();
  }

  async batchUpdate(updates: GenericUpdate[]) {
    const writeBatch = this._afs.firestore.batch();
    for(const update of updates) {
      if(update.type === UpdateType.Update) {
        writeBatch.update(this._afs.doc(update.documentQuery).ref, (update as Update).update);
      } else if(update.type === UpdateType.Delete) {
        writeBatch.delete(this._afs.doc(update.documentQuery).ref);
      } else if(update.type === UpdateType.Create) {
        writeBatch.set(this._afs.doc(update.documentQuery).ref, (update as Create).value);
      }
    }
    await writeBatch.commit();
  }
}
