<div class="form-container">
    <div class="sign-in-toggle-header">
        <div class="sign-in-toggle toggle-option"
            [class.active]="loggingIn"
            (click)="switchToLogin()"> Sign In </div>
        <div class="sign-up-toggle toggle-option"
            [class.active]="!loggingIn"
            (click)="switchToSignup()"> Sign Up </div> 
    </div>
    <ng-template [ngIf]="loggingIn" [ngIfElse]="signup">
        <div class="form">
            <div class="input-container">
                <div class="input-icon-container">
                    <fa-icon [icon]="emailIcon"></fa-icon>
                </div>
                <input #email type="text" placeholder="Enter Email"> <br>
            </div>
            <div class="input-container">
                <div class="input-icon-container">
                    <fa-icon [icon]="passwordIcon"></fa-icon>
                </div>
                <input #password type="password" placeholder="Enter Password"> <br>
            </div>
            <button class="form-action-btn rounded medium" (click)="login(email.value, password.value)"> Sign In </button>
            <div (click)="sendForgotPasswordEmail()" class="forgot-password-container"> Forgot Password? </div> 
        </div>
        
    </ng-template>
</div>

<ng-template #signup>
    <div class="form">
        <div class="input-container">
            <div class="input-icon-container">
                <fa-icon [icon]="fullNameIcon"></fa-icon>
            </div>
            <input #fullName type="text" placeholder="Full Name"> <br>
        </div>
        <div class="input-container">
            <div class="input-icon-container">
                <fa-icon [icon]="emailIcon"></fa-icon>
            </div>
            <input #email type="text" placeholder="Enter Email"> <br>
        </div>
        <div class="input-container">
            <div class="input-icon-container">
                <img src="../../../assets/images/lichess-presskit/lichesslogowhite.svg">
            </div>
            <input #lichess type="text" placeholder="Enter Lichess Username"> <br>
        </div>
        <div class="input-container">
            <div class="input-icon-container">
                <fa-icon [icon]="passwordIcon"></fa-icon>
            </div>
            <input #password type="password" placeholder="Enter Password"> <br>
        </div>
        <button class="form-action-btn rounded medium" 
            (click)="registerUser(fullName.value, email.value, lichess.value, password.value)"> Sign Up </button>
    </div>
    
</ng-template>


