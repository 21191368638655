<ng-container *ngIf="loaded">
    <div class="full-page-container">
        <div (click)="exitWatchNow()" class="full-page-close-icon">
            X
        </div>
        <div class="full-page-header">
            <div class="channel-changer">
                <div 
                    class="channel"
                    [class.active]="channel === activeChannel"
                    [class.offline]="!channel.liveStream"
                    [class.live]="channel.liveStream"
                    *ngFor="let channel of channels" 
                    (click)="changeChannel(channel)">
                    {{channel.username}}
                    <div class="channel-status" *ngIf="channel.liveStream"> LIVE </div>
                    <div class="channel-status" *ngIf="!channel.liveStream">Offline</div> 
                </div>
            </div>
        </div>
        <div class="full-page-content">
            <div class="tv-container" *ngIf="activeChannel">
                <iframe 
                    class="tv-iframe"
                    [src]="getTwitchChannel()" 
                    frameborder="0" 
                    allowfullscreen="true" 
                    scrolling="no">
                </iframe>
                <iframe 
                    class="tv-chat-iframe"
                    [src]="getTwitchChat()" 
                    frameborder="0" 
                    scrolling="no" 
                    height="500" 
                    width="350">
                </iframe>
            </div>
            <div class="nbl-offline-container" *ngIf="!activeChannel">
                <img src="../../../assets/images/NBL-Offline.png">
            </div>
        </div>
    </div>
</ng-container>

