<ng-container *ngIf="loaded">
    <div class="player-stats-view-container" class="panel-container">
        <div class="panel-header">
            <select id="season-select" class="dropdown" (change)="handleSeasonSelectionChange($event.target.value)">
                <option 
                    *ngFor="let season of allSeasons; let isFirst = first" 
                    [value]="season.id"
                    [attr.selected]="isFirst ? true : null"> Season {{season.id}} </option>
                <option value="allSeasons"> Career </option>
            </select>
        </div>
        <div class="player-stats-container" class="panel">
            <table *ngIf="playerStats.length">
                <tr>
                    <th> Rank </th>
                    <th (click)="handleSortByHeaderClick('fullName')"> Player Name </th>
                    <th (click)="handleSortByHeaderClick('teamName')"> Team Name </th>
                    <th (click)="handleSortByHeaderClick('bulletPpg')"> Bullet Points Per Arena </th>
                    <th (click)="handleSortByHeaderClick('bulletTotalPoints')"> Bullet Total Points </th>
                    <th (click)="handleSortByHeaderClick('blitzPpg')"> Blitz Points Per Arena </th>
                    <th (click)="handleSortByHeaderClick('blitzTotalPoints')"> Blitz Total Points </th>
                    <th (click)="handleSortByHeaderClick('totalArenas')"> Num Arenas </th>
                    <th (click)="handleSortByHeaderClick('totalPoints')"> Total Points </th>
                </tr>
                <tr *ngFor="let playerStat of playerStats; let i = index">
                    <td> {{i + 1}} </td>
                    <td> {{playerStat.fullName}} </td>
                    <td> {{playerStat.teamName}} </td>
                    <td> {{playerStat.bulletPpg}} </td>
                    <td> {{playerStat.bulletTotalPoints}} </td>
                    <td> {{playerStat.blitzPpg}} </td>
                    <td> {{playerStat.blitzTotalPoints}} </td>
                    <td> {{playerStat.totalArenas}} </td>
                    <td> {{playerStat.totalPoints}} </td>
                </tr> 
            </table>
            <h2 *ngIf="!playerStats.length"> Sorry no player stats available for this season. </h2>
        </div>    
    </div>    
</ng-container>
