import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

type UIElement = 'LoginBtn' | 'RegisterBtn' | 'LogoutBtn';

interface ClickAction {
  type: 'Click';
  data: UIElement;
}

interface HoverAction {
  type: 'Hover';
  data: UIElement;
}

type AnalyticsEvent = ClickAction | HoverAction;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private _analytics: AngularFireAnalytics) { }

  logEvent(event: AnalyticsEvent) {
    this._analytics.logEvent(event.type, {uiElement: event.data});
  }
}
