<ng-container *ngIf="loaded">
    <div id="admin-view-content">
        <div class="table-container scrollable">
            <table *ngIf="hydratedTeam.hydratedPlayers && hydratedTeam.hydratedPlayers.length">
                <tr>
                    <th> # </th>
                    <th> Name </th>
                    <th> Username </th>
                    <th> Actions </th>
                </tr>
                <tr *ngFor="let player of hydratedTeam.hydratedPlayers; let i = index">
                    <td> {{ i + 1 }}</td>
                    <td> {{player.name}} </td> 
                    <td> {{player.username}} </td>
                    <td> <button class="small" (click)="dropPlayer(player.id)"> Drop Player </button> </td>
                </tr>
            </table>
        </div>
        <div class="action-container">
            <button class="small" (click)="addPlayer()"> Add Player</button>
        </div>
    </div>
    <div *ngIf="modalVisible">
        <awesome-ui-modal [handleClose]="handleModalClose.bind(this)">
            <div class="header">
                <h1> Select Player to Add </h1>
            </div>
            <app-players-table [filterFunction]="playerIsFreeAgent" (playerSelected)="handlePlayerSelected($event)"></app-players-table>
        </awesome-ui-modal>
    </div>    
</ng-container>
