import { Injectable } from '@angular/core';
import { default as swal } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PromptService {

  constructor() { }

  async prompt(text: string, inputType: 'text' | 'password' = 'text') {
    const { value } = await swal.fire({
      title: text,
      input: inputType,
      showCancelButton: true,
    });
    return value;
  }

  async promptSelect(text: string, options: string[]) {
    const inputOptions = {};
    for(const option of options) {
      inputOptions[option] = option;
    }
    const { value } = await swal.fire({
      title: text,
      input: 'select',
      showCancelButton: true,
      inputOptions,
    });
    return value;
  }

  async successAlert(msg: string) {
    await swal.fire({
      icon: 'success',
      title: 'Sweet!',
      text: msg,
    });
  }

  async errorAlert(msg: string) {
    await swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: msg,
    });
  }

  async unknownErrorAlert() {
    await swal.fire({
      icon: 'error',
      title: 'Uhoh',
      text: 'Something went wrong. Please try again!',
    });
  }
}
