<ng-container *ngIf="loaded">
    <div class="league-admin-panel">
        <div class="header">
            <h1> Season {{activeSeason.id}} </h1>
        </div>
        <div class="table-container scrollable">
            <table *ngIf="activeSeason.tournaments && activeSeason.tournaments.length">
                <tr>
                    <th> # </th>
                    <th> Tournament ID </th>
                    <th> Tournament Link </th>
                    <th> Actions </th>
                </tr>
                <tr *ngFor="let tournamentId of activeSeason.tournaments; let i = index">
                    <td> {{i + 1}}
                    <td> {{tournamentId}} </td>
                    <td> 
                        <a [href]="'https://lichess.org/tournament/' + tournamentId"
                            target="_blank">
                            <button class="small"> Go To Tournament </button>
                        </a> 
                    </td>
                    <td> <button (click)="deleteTournament(tournamentId)" class="small"> Delete Tournament </button> </td>
                </tr>
            </table>
        </div>
        <div class="action-container">
            <button class="small" (click)="createNewTournament()"> Create New Tournament </button>
        </div>
    </div>
</ng-container>