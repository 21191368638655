import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HydratedTeam, TeamDataService } from 'src/app/services/data/team-data/team-data.service';
import { HydratedPlayer, PlayerDataService } from 'src/app/services/data/player-data/player-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-players-team-admin-view',
  templateUrl: './players-team-admin-view.component.html',
  styleUrls: ['./players-team-admin-view.component.scss']
})
export class PlayersTeamAdminViewComponent implements OnInit {
  loaded = false;
  routerSub: Subscription;
  teamId: string;
  hydratedTeam: HydratedTeam;
  modalVisible = false;
  constructor(private route: ActivatedRoute, private _teamData: TeamDataService, private _playerData: PlayerDataService) { }

  async ngOnInit() {
    this.routerSub = this.route.parent.params.subscribe(async (params) => {
      this.loaded = false;
      this.teamId = params.teamId;
      await this.renderTeamData();
      this.loaded = true;
    });
  }

  async renderTeamData(teamId?: string) {
    this.hydratedTeam = await this._teamData.getTeam(teamId || this.teamId);
  }

  async dropPlayer(playerId: string) {
    await this._playerData.dropPlayer(playerId);
    this.renderTeamData();
  }

  playerIsFreeAgent(player: HydratedPlayer) {
    return player.team === 'Free Agent';
  }

  addPlayer() {
    this.modalVisible = true;
  }

  async handlePlayerSelected(player: HydratedPlayer) {
    await this._teamData.addPlayerToTeam(this.teamId, player.id);
    this.modalVisible = false;
    this.renderTeamData();
  }

  handleModalClose() {
    this.modalVisible = false;
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }

}
