<ng-container *ngIf="loaded">
    <div class="standings-view-container" class="panel-container">
        <div class="panel-header">
            <select id="time-control-select" class="dropdown" (change)="handleTimeControlSelectionChange($event.target.value)">
                <option value="Bullet"> Bullet </option>
                <option value="Blitz"> Blitz </option>
            </select>
        </div>
        <div class="standings-container" class="panel">
            <awesome-ui-table [dataSource]="dataSource"></awesome-ui-table>
        </div>    
    </div>
</ng-container>

