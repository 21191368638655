import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { HomeViewComponent } from './views/home-view/home-view.component';
import { PageNotFoundViewComponent } from './views/page-not-found-view/page-not-found-view.component';
import { AdminViewComponent } from './views/admin-view/admin-view.component';
import { AwesomeUiModule } from './modules/awesome-ui/awesome-ui.module';
import { PlayerStatsViewComponent } from './views/player-stats-view/player-stats-view.component';
import { StandingsViewComponent } from './views/standings-view/standings-view.component';
import { ScheduleViewComponent } from './views/schedule-view/schedule-view.component';
import { WatchNowViewComponent } from './views/watch-now-view/watch-now-view.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TeamAdminViewComponent } from './views/admin-view/team-admin-view/team-admin-view.component';
import { PlayerAdminViewComponent } from './views/admin-view/player-admin-view/player-admin-view.component';
import { LeagueAdminViewComponent } from './views/admin-view/league-admin-view/league-admin-view.component';
import { PlayersTableComponent } from './components/players-table/players-table.component';
import { TeamsLeagueAdminViewComponent } from './views/admin-view/league-admin-view/teams-league-admin-view/teams-league-admin-view.component';
import { TournamentsLeagueAdminViewComponent } from './views/admin-view/league-admin-view/tournaments-league-admin-view/tournaments-league-admin-view.component';
import { PlayersLeagueAdminViewComponent } from './views/admin-view/league-admin-view/players-league-admin-view/players-league-admin-view.component';
import { PlayersTeamAdminViewComponent } from './views/admin-view/team-admin-view/players-team-admin-view/players-team-admin-view.component';
import { TeamsTeamAdminViewComponent } from './views/admin-view/team-admin-view/teams-team-admin-view/teams-team-admin-view.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginViewComponent,
    HomeViewComponent,
    PageNotFoundViewComponent,
    AdminViewComponent,
    PlayerStatsViewComponent,
    StandingsViewComponent,
    ScheduleViewComponent,
    WatchNowViewComponent,
    TeamAdminViewComponent,
    PlayerAdminViewComponent,
    LeagueAdminViewComponent,
    PlayersTableComponent,
    TeamsLeagueAdminViewComponent,
    TournamentsLeagueAdminViewComponent,
    PlayersLeagueAdminViewComponent,
    PlayersTeamAdminViewComponent,
    TeamsTeamAdminViewComponent,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AwesomeUiModule,
    FormsModule,
  ],
  providers: [ScreenTrackingService, UserTrackingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
