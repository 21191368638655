<ng-container *ngIf="!areaSelected && loaded">
    <h1> Select Area to Manage </h1>
    <div class='nav-container'>
        <div 
        class="card clickable" 
        [routerLink]="'/admin/league'" 
        routerLinkActive="active"
        *ngIf="myPlayer.role?.admin">
            <div class="icon">
                <fa-icon [icon]="adminIcon"></fa-icon>
            </div>
            <div class="content">
                Manage League 
            </div>
        </div>
        <div 
        class="card clickable" 
        [routerLink]="['/admin/team', myPlayer.team]" 
        routerLinkActive="active"
        *ngIf="myPlayer.role?.teamOwner"> 
            <div class="icon">
                <fa-icon [icon]="teamIcon"></fa-icon>
            </div>
            Manage My Team 
        </div>
        <div 
        class="card clickable" 
        [routerLink]="['/admin/player', myPlayer.id]" 
        routerLinkActive="active"
        *ngIf="myPlayer.role?.player"> 
            <div class="icon">
                <fa-icon [icon]="userIcon"></fa-icon>
            </div>
            Manage My Player 
        </div>
    </div>
</ng-container>
<ng-container *ngIf="areaSelected && numRoles > 1">
    <div class="back-button-container clickable" (click)="goBack()">
        <div class="icon-container">
            <fa-icon [icon]="backIcon"></fa-icon>
        </div>
        <div class="text-container">
            Back to Selection Area
        </div>
         
    </div>
</ng-container>
<router-outlet></router-outlet>