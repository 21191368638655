import { Injectable } from '@angular/core';
import { DataService, Team, Player } from '../data.service';

export interface HydratedTeam extends Team {
  hydratedPlayers: Player[];
}

@Injectable({
  providedIn: 'root'
})
export class TeamDataService {

  constructor(private _data: DataService) { }

  async getTeams() {
    const teams = await this._data.getCollection<Team>('teams');
    return this.hydrateTeams(teams);
  }

  async getTeam(teamId: string) {
    const team = await this._data.getDocument<Team>(`teams/${teamId}`);
    return (await this.hydrateTeams([team]))[0];
  }

  async getActiveTeams() {
    const teams = await this.getTeams();
    return teams.filter((team) => {
      return team.active;
    });
  }

  async hydrateTeams(teams: Team[]) {
    const players = await this._data.getCollectionAsMap<Player>('players');
    const hydratedTeams = teams.map((team) => {
      const hydratedPlayers = team.players.map((player) => {
        const hydratedPlayer = players.get(player);
        if(!hydratedPlayer) {
          console.warn("Failed to find player ", player, " when hydrating players.");
        }
        return players.get(player);
      });
      (team as HydratedTeam).hydratedPlayers = hydratedPlayers;
      return (team as HydratedTeam);
    });

    return hydratedTeams;
  }

  async addPlayerToTeam(teamId: string, playerId: string) {
    return await this._data.updateDocument(`teams/${teamId}`, {
      players: this._data.arrayUnion(playerId),
    });
  }
}
