<div class="league-admin-view-container">
    <div class="league-admin-nav">
        <div class="item clickable" routerLink="/admin/league/tournaments" routerLinkActive="active"> Manage Tournaments </div>
        <!-- <div class="item"> 
            <a routerLink="/admin/league/tournaments">Manage Players </a> 
        </div> -->
        <div class="item clickable" routerLink="/admin/league/teams" routerLinkActive="active"> Manage Teams </div>
    </div>
    <router-outlet></router-outlet>
</div>
