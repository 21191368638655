import { Component, OnInit, Input } from '@angular/core';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
export type data = number|string;

export interface Header {
  index: number;
  display: string;
  value: data;
  sortable: boolean;
  primary?: boolean;
}
export interface Row {
  cols: data[];
}
export interface DataSource {
  headers: Header[];
  rows: Row[];
}

@Component({
  selector: 'awesome-ui-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input('dataSource') dataSource: DataSource;
  descending = true;
  headerToSortBy: string;
  faSortUp = faSortUp;
  faSortDown = faSortDown;

  constructor() { }

  ngOnInit(): void {
    for(const header of this.dataSource.headers) {
      if(header.primary) {
        this.sortByHeader(header);
      }
    }
  }

  sortByHeader(header: Header) {
    if(this.headerToSortBy === header.display) {
      this.descending = !this.descending;
    } else {
      this.headerToSortBy = header.display;
    }

    this.dataSource.rows.sort((rowA, rowB) => {
      if(this.descending) {
        return (rowB.cols[header.index] as number)
        - (rowA.cols[header.index] as number);
      } else {
        return (rowA.cols[header.index] as number)
        - (rowB.cols[header.index] as number);
      }
    });
  }

}
