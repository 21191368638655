// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCgFKEYFtyt8--PNJOx2wdK9Y2tNC6Qimw",
    authDomain: "national-blitz-league-dev.firebaseapp.com",
    databaseURL: "https://national-blitz-league-dev.firebaseio.com",
    projectId: "national-blitz-league-dev",
    storageBucket: "national-blitz-league-dev.appspot.com",
    messagingSenderId: "325821826707",
    appId: "1:325821826707:web:de65eae243b52dec996f66",
    measurementId: "G-2GD3NMCJGC"
  },
  functionsEndpoint: 'https://us-central1-national-blitz-league-v2.cloudfunctions.net',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
