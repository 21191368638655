import { Component, OnInit } from '@angular/core';
import { SeasonDataService, HydratedSeason } from 'src/app/services/data/season-data/season-data.service';

export interface ScheduleItem {
  date: string;
  timeControl: 'Blitz' | 'Bullet';
  time: string;
  streamLink: string;
  commentator: string;
  rawDate: number;
}

@Component({
  selector: 'app-schedule-view',
  templateUrl: './schedule-view.component.html',
  styleUrls: ['./schedule-view.component.scss']
})
export class ScheduleViewComponent implements OnInit {
  schedule: ScheduleItem[];
  loaded = false;
  constructor(private _seasonData: SeasonDataService) { }

  async ngOnInit() {
    const seasons = await this._seasonData.getHydratedSeasons();
    this.schedule = this.generateScheduleFromSeason(seasons[seasons.length - 1]);
    this.loaded = true;
  }

  generateScheduleFromSeason(hydratedSeason: HydratedSeason) {
    const { startDate, numWeeks } = hydratedSeason;
    const schedule: ScheduleItem[] = [];
    let curDate = new Date(startDate);
    // Go forward a day to set UTC Time
    curDate = this.incrementByDays(curDate, 1);
    curDate.setUTCHours(1); // 1 AM === 8 PM CST
    let isBullet = true;
    let isMonday = true;
    for(let i = 0; i < numWeeks * 2; i++) {
      schedule.push({
        date: curDate.toLocaleDateString(),
        timeControl: isBullet ? 'Bullet' : 'Blitz',
        time: curDate.toLocaleTimeString(),
        streamLink: 'https://www.twitch.tv/professordaddi',
        commentator: 'NM Gopal Menon',
        rawDate: curDate.getTime(),
      });
      isBullet = !isBullet;
      curDate = this.incrementByDays(curDate, isMonday ? 2 : 5);
      isMonday = !isMonday;
    }
    return schedule;
  }

  incrementByDays(date: Date, numDays: number) {
    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;
    const day = 24 * hour;
    const origDateInMs = date.getTime();
    const newDateInMs = origDateInMs + (numDays * day);
    const newDate = new Date(newDateInMs);
    return newDate;
  }

  now() {
    return new Date().getTime();
  }
}
