import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { HomeViewComponent } from './views/home-view/home-view.component';
import { redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { PageNotFoundViewComponent } from './views/page-not-found-view/page-not-found-view.component';
import { AdminViewComponent } from './views/admin-view/admin-view.component';
import { StandingsViewComponent } from './views/standings-view/standings-view.component';
import { ScheduleViewComponent } from './views/schedule-view/schedule-view.component';
import { PlayerStatsViewComponent } from './views/player-stats-view/player-stats-view.component';
import { WatchNowViewComponent } from './views/watch-now-view/watch-now-view.component';
import { LeagueAdminViewComponent } from './views/admin-view/league-admin-view/league-admin-view.component';
import { TeamAdminViewComponent } from './views/admin-view/team-admin-view/team-admin-view.component';
import { PlayerAdminViewComponent } from './views/admin-view/player-admin-view/player-admin-view.component';
import { TournamentsLeagueAdminViewComponent } from './views/admin-view/league-admin-view/tournaments-league-admin-view/tournaments-league-admin-view.component';
import { TeamsLeagueAdminViewComponent } from './views/admin-view/league-admin-view/teams-league-admin-view/teams-league-admin-view.component';
import { PlayersLeagueAdminViewComponent } from './views/admin-view/league-admin-view/players-league-admin-view/players-league-admin-view.component';
import { PlayersTeamAdminViewComponent } from './views/admin-view/team-admin-view/players-team-admin-view/players-team-admin-view.component';
import { TeamsTeamAdminViewComponent } from './views/admin-view/team-admin-view/teams-team-admin-view/teams-team-admin-view.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: 'home',
    component: HomeViewComponent,
  },
  {
    path: 'login',
    component: LoginViewComponent,
  },
  {
    path: 'standings',
    component: StandingsViewComponent,
  },
  {
    path: 'schedule',
    component: ScheduleViewComponent,
  },
  {
    path: 'player-stats',
    component: PlayerStatsViewComponent,
  },
  {
    path: 'watch-now',
    component: WatchNowViewComponent,
  },
  {
    path: 'admin',
    component: AdminViewComponent,
    children: [
      { 
        path: 'league', 
        component: LeagueAdminViewComponent,
        children: [
          { path: 'tournaments', component: TournamentsLeagueAdminViewComponent },
          { path: 'teams', component: TeamsLeagueAdminViewComponent },
          { path: '', redirectTo: 'tournaments', pathMatch: 'full'},
          // { path: 'players', component: PlayersLeagueAdminViewComponent },
        ]
      },
      { 
        path: 'team/:teamId', 
        component: TeamAdminViewComponent,
        children: [
          { path: 'players', component: PlayersTeamAdminViewComponent },
          { path: 'team', component: TeamsTeamAdminViewComponent },
          { path: '', redirectTo: 'players', pathMatch: 'full'},
        ]
      },
      { path: 'player/:playerId', component: PlayerAdminViewComponent },
    ],
  },
  {
    path: '',
    component: HomeViewComponent,
  },
  {path: '*', component: PageNotFoundViewComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
