import { Component, OnInit } from '@angular/core';
import { TeamDataService, HydratedTeam } from 'src/app/services/data/team-data/team-data.service';
import { DataService, Team } from 'src/app/services/data/data.service';
import { PromptService } from 'src/app/services/prompt/prompt.service';

@Component({
  selector: 'app-teams-league-admin-view',
  templateUrl: './teams-league-admin-view.component.html',
  styleUrls: ['./teams-league-admin-view.component.scss']
})
export class TeamsLeagueAdminViewComponent implements OnInit {
  teams: HydratedTeam[];
  loaded = false;
  constructor(private _teamsData: TeamDataService, private _data: DataService, private _prompt: PromptService) { }

  async ngOnInit(){
    this.teams = await this._teamsData.getActiveTeams();
    this.loaded = true;
  }

  async saveTeam(team: Team) {
    try {
      await this._data.updateDocument(`teams/${team.id}`, team);
      this._prompt.successAlert("Team updated successfully");
    } catch(err) {
      this._prompt.errorAlert(err.message);
    }
  }

}
