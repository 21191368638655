import { Component, OnInit } from '@angular/core';
import { SeasonDataService, HydratedSeason } from 'src/app/services/data/season-data/season-data.service';
import { TimeControl, Tournament, Team } from 'src/app/services/data/data.service';
import { DataSource, Header, Row } from 'src/app/modules/awesome-ui/table/table.component';

@Component({
  selector: 'app-standings-view',
  templateUrl: './standings-view.component.html',
  styleUrls: ['./standings-view.component.scss']
})
export class StandingsViewComponent implements OnInit {
  hydratedSeasons: HydratedSeason[];
  loaded = false;
  activeSeasonId: string;
  selectedTimeControl: TimeControl = TimeControl.Bullet;
  selectedTournaments: Tournament[];
  selectedTotalPoints: Map<string, number> = new Map(); // Map from team ID to total points scored
  sortedTeams: Team[];
  dataSource: DataSource;

  constructor(private _seasonData: SeasonDataService) { }

  async ngOnInit() {
    this.hydratedSeasons = await this._seasonData.getHydratedSeasons();
    this.activeSeasonId = this.hydratedSeasons[this.hydratedSeasons.length - 1].id;
    this.hydrateSelectedTournaments();
    this.generateDataSource();
    this.loaded = true;
  }

  handleTimeControlSelectionChange(timeControl: TimeControl) {
    this.selectedTimeControl = timeControl;
    this.hydrateSelectedTournaments();
    this.generateDataSource();
  }

  hydrateSelectedTournaments() {
    const activeSeason = this.hydratedSeasons[this.hydratedSeasons.length - 1];
    this.selectedTournaments = activeSeason.hydratedTournaments.filter((hydratedTournament) => {
      return hydratedTournament.timeControl === this.selectedTimeControl;
    });
    this.selectedTotalPoints.clear();
    for (const selectedTournament of this.selectedTournaments) {
      for (const [teamId, result] of Object.entries(selectedTournament.teamResults)) {
        const teamTotalPoints = this.selectedTotalPoints.get(teamId) || 0;
        this.selectedTotalPoints.set(teamId, teamTotalPoints + result);
      }
    }
  }

  generateDataSource() {
    const headers = this.generateDataHeaders();
    const rows = this.generateDataRows();
    this.dataSource = {
      headers,
      rows,
    };
  }

  generateDataHeaders() {
    const arenaHeaders = [];
    for (let i = 0; i < this.selectedTournaments.length; i++) {
      arenaHeaders.push({
        display: `Arena #${i + 1}`,
        value: `arena${i + 1}`,
        sortable: true,
        index: i + 1,
      });
    }
    const headers: Header[] = [
        {
          display: 'Team Name',
          value: 'teamName',
          sortable: false,
          index: 1,
        },
        ...arenaHeaders,
        {
          display: 'Trade Penalties',
          value: 'tradePenalties',
          sortable: true,
          index: arenaHeaders.length + 1,
        },
        {
          display: 'Total Points',
          value: 'totalPoints',
          sortable: true,
          index: arenaHeaders.length + 2,
          primary: true,
        },
    ];
    return headers;
  }

  generateDataRows() {
    const rows: Row[] = [];
    const activeSeason = this.hydratedSeasons.find((hydratedSeason) => {
      return hydratedSeason.id === this.activeSeasonId;
    });
    for (let i = 0; i < activeSeason.hydratedTeams.length; i++) {
      const team = activeSeason.hydratedTeams[i];
      const cols = [];
      cols.push(team.name);
      for (let j = 0; j < this.selectedTournaments.length; j++) {
        const selectedTournament = this.selectedTournaments[j];
        cols.push(selectedTournament.teamResults?.[team.id]);
      }
      const tradePenalties = this.getTradePenaltyMultiplier(team.numPenalties);
      cols.push(tradePenalties);
      cols.push(this.selectedTotalPoints.get(team.id) - tradePenalties);
      rows.push({
        cols,
      });
    }
    return rows;
  }

  private getTradePenaltyMultiplier(numPenalties: number) {
    const multiplier = this.selectedTimeControl === 'Blitz' ? 50 : 150;
    return numPenalties * multiplier;
  }
}
