<table>
    <tr>
        <th> Rank </th>
        <th *ngFor="let header of dataSource.headers" (click)="sortByHeader(header)" [class.sortable]="header.sortable"> 
            {{header.display}} &nbsp;
            <fa-icon *ngIf="header.sortable" [icon]="descending ? faSortDown : faSortUp"></fa-icon> 
        </th>
    </tr>
    <tr *ngFor="let row of dataSource.rows; let i = index">
        <td>{{ i + 1 }}</td>
        <td *ngFor="let col of row.cols"> {{col}} </td>
    </tr>
</table>