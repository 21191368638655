import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teams-team-admin-view',
  templateUrl: './teams-team-admin-view.component.html',
  styleUrls: ['./teams-team-admin-view.component.scss']
})
export class TeamsTeamAdminViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
