import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { faSitemap, faUsers, faUser, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Player } from 'src/app/services/data/data.service';
import { Router } from '@angular/router';

type ViewType = 'tournamentView' | 'teamsView' | 'playersView';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnInit {
  loaded = false;
  myPlayer: Player;
  adminIcon = faSitemap;
  teamIcon = faUsers;
  userIcon = faUser;
  backIcon = faArrowCircleLeft;
  areaSelected = false;
  numRoles: number;

  constructor(private _auth: AuthService, private _router: Router) { }

  async ngOnInit() {
    this.areaSelected = !this._router.url.endsWith('admin');
    this._router.events.subscribe(() => {
      this.areaSelected = !this._router.url.endsWith('admin');
    });
    this.myPlayer = await this._auth.getMyPlayer();
    this.numRoles = Object.keys(this.myPlayer.role).length;
    if(this.numRoles <= 1) {
      this._router.navigate([`/admin/player`, this.myPlayer.id]);
    }
    this.loaded = true;
  }

  goBack() {
    this._router.navigate(['/admin']);
  }
}
