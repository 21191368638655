import { Component, OnInit } from '@angular/core';
import { PlayerDataService, HydratedPlayer } from 'src/app/services/data/player-data/player-data.service';
import { PromptService } from 'src/app/services/prompt/prompt.service';
import { DataService, Season, UpdateType, Player } from 'src/app/services/data/data.service';
import { TeamDataService, HydratedTeam } from 'src/app/services/data/team-data/team-data.service';
import { HttpService, Endpoints } from 'src/app/services/http.service';

@Component({
  selector: 'app-tournaments-league-admin-view',
  templateUrl: './tournaments-league-admin-view.component.html',
  styleUrls: ['./tournaments-league-admin-view.component.scss']
})
export class TournamentsLeagueAdminViewComponent implements OnInit {
  players: HydratedPlayer[];
  loaded = false;
  activeSeason: Season;
  teams: HydratedTeam[];

  constructor(private _playerData: PlayerDataService,
    private _prompt: PromptService,
    private _data: DataService,
    private _teamData: TeamDataService,
    private _http: HttpService) { }

  async ngOnInit( ){
    const seasons = await this._data.getCollection<Season>('seasons');
    this.activeSeason = seasons[seasons.length - 1];
    this.players = await this._playerData.getHydratedPlayers();

    this.loaded = true;
  }

  async createNewTournament() {
    const tournamentId = await this._prompt.prompt('Enter lichess tournament ID');
    if (!tournamentId) { return; }
    const timeControl = await this._prompt.promptSelect('Select tournament time control', ['Bullet', 'Blitz']);
    if (!timeControl) { return; }
    const seasonUpdate = {
      documentQuery: `/seasons/${this.activeSeason.id}`,
      update: {
        tournaments: this._data.arrayUnion(tournamentId),
      },
      type: UpdateType.Update,
    };
    const tournamentsUpdate = {
      documentQuery: `/tournaments/${tournamentId}`,
      value: {
        seasonId: this.activeSeason.id,
        timeControl,
        results: [],
      },
      type: UpdateType.Create,
    };
    await this._data.batchUpdate([seasonUpdate, tournamentsUpdate]);
    await this._http.get(Endpoints.mainHTTPSJob);
    this._prompt.successAlert('Tournament created successfully.');
    this.refreshData();
  }

  async deleteTournament(tournamentId: string) {
    const seasonUpdate = {
      documentQuery: `/seasons/${this.activeSeason.id}`,
      update: {
        tournaments: this._data.arrayRemove(tournamentId),
      },
      type: UpdateType.Update,
    };
    const tournamentsUpdate = {
      documentQuery: `/tournaments/${tournamentId}`,
      type: UpdateType.Delete,
    };

    await this._data.batchUpdate([seasonUpdate, tournamentsUpdate]);
    this.refreshData();
  }

  async refreshData() {
    this.teams = await this._teamData.getTeams();
    this.players = await this._playerData.getHydratedPlayers();
  }
}
