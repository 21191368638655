import { Component, OnInit } from '@angular/core';
import { LichessService } from 'src/app/services/lichess/lichess.service';
import { DataService, Season } from 'src/app/services/data/data.service';
import { PlayerDataService, HydratedPlayer } from 'src/app/services/data/player-data/player-data.service';
import {cloneDeep} from 'lodash';

interface PlayerStat {
  fullName: string;
  username: string;
  blitzPpg: number;
  bulletPpg: number;
  blitzTotalPoints: number;
  blitzTotalArenas: number;
  bulletTotalPoints: number;
  bulletTotalArenas: number;
  totalArenas: number;
  totalPoints: number;
}

export type SeasonVals = 'currentSeason' | 'allSeasons' | string;

@Component({
  selector: 'app-player-stats-view',
  templateUrl: './player-stats-view.component.html',
  styleUrls: ['./player-stats-view.component.scss']
})
export class PlayerStatsViewComponent implements OnInit {
  playerStats: PlayerStat[];
  loaded = false;
  descending = true;
  sortedByField: string;
  activeSeason: Season;
  allSeasons: Season[];
  players: HydratedPlayer[];

  constructor(
    private _data: DataService,
    private _lichess: LichessService,
    private _playerData: PlayerDataService) { }

  async ngOnInit() {
    this.allSeasons = await (await this._data.getCollection<Season>('seasons')).reverse();
    this.activeSeason = this.allSeasons[0];
    this.players = await this._playerData.getHydratedPlayers();
    await this.render();
    this.loaded = true;
  }

  async sortByHeader(field) {
    this.playerStats.sort((playerA, playerB) => {
      if (this.descending) {
        if (!playerA[field]) {
          return 1;
        }
        if (!playerB[field]) {
          return -1;
        }
        return playerB[field] - playerA[field];
      } else {
        if (!playerA[field]) {
          return -1;
        }
        if (!playerB[field]) {
          return 1;
        }
        return playerA[field] - playerB[field];
      }
    });
  }

  async handleSeasonSelectionChange(seasonVal: string) {
    const seasonValAsNumber = Number(seasonVal);
    if (seasonVal === 'allSeasons') {
      this.render(/* renderAllSeasons= */ true);
    } else {
      this.activeSeason = this.allSeasons[this.allSeasons.length - seasonValAsNumber];
      this.render();
    }
  }

  async render(renderAllSeasons = false) {
    this.playerStats = this.players
      .map((player) => {
        let blitzStats = {
          numGames: 0,
          points: 0,
          ppg: 0,
        };
        let bulletStats = {...blitzStats};
        if (renderAllSeasons) {
          const seasonStats = Object.values(player.seasons)
            .reduce((season, seasonStatsAccum) => {
              seasonStatsAccum = cloneDeep(seasonStatsAccum);
              if (!seasonStatsAccum) { seasonStatsAccum = {blitzStats, bulletStats}; }
              if (!seasonStatsAccum.blitzStats) { seasonStatsAccum.blitzStats = blitzStats; }
              if (!seasonStatsAccum.bulletStats) { seasonStatsAccum.bulletStats = bulletStats; }
              seasonStatsAccum.blitzStats.numGames += season.blitzStats?.numGames || 0;
              seasonStatsAccum.blitzStats.points += season.blitzStats?.points || 0;
              seasonStatsAccum.blitzStats.ppg = seasonStatsAccum.blitzStats.points / seasonStatsAccum.blitzStats?.numGames;
              seasonStatsAccum.bulletStats.numGames += season.bulletStats?.numGames || 0;
              seasonStatsAccum.bulletStats.points += season.bulletStats?.points || 0;
              seasonStatsAccum.bulletStats.ppg = seasonStatsAccum.bulletStats?.points / seasonStatsAccum.bulletStats?.numGames;
              return seasonStatsAccum;
            });
          blitzStats = seasonStats.blitzStats || blitzStats;
          bulletStats = seasonStats.bulletStats || bulletStats;
        } else {
          if (!player.seasons[this.activeSeason.id]) { return null; }
          const seasonStats = player.seasons[this.activeSeason.id];
          if (seasonStats.blitzStats) { blitzStats = seasonStats.blitzStats; }
          if (seasonStats.bulletStats) { bulletStats = seasonStats.bulletStats; }
        }
        return {
          fullName: player.name,
          username: player.username,
          teamName: player.hydratedTeam.name,
          blitzPpg: Math.round(blitzStats.ppg),
          bulletPpg: Math.round(bulletStats.ppg),
          blitzTotalPoints: Math.round(blitzStats.points),
          bulletTotalPoints: Math.round(bulletStats.points),
          blitzTotalArenas: blitzStats.numGames,
          bulletTotalArenas: bulletStats.numGames,
          totalArenas: blitzStats.numGames + bulletStats.numGames,
          totalPoints: blitzStats.points + bulletStats.points,
        };
      })
      .filter((playerStat) => {
        return Boolean(playerStat);
      });
    this.sortByHeader('totalPoints');
  }

  handleSortByHeaderClick(field: string) {
    if (field === this.sortedByField) {
      this.descending = !this.descending;
    } else {
      this.sortedByField = field;
    }
    this.sortByHeader(field);
  }
}
