<table>
    <tr>
       <th> # </th>
       <th> Name </th>
       <th> Username </th>
       <th> Team </th>
       <th> Action </th>
    </tr>
    <tr *ngFor="let player of players; let i = index">
        <td> {{ i + 1 }} </td>
        <td> {{player.name}} </td>
        <td> {{player.username}}</td>
        <td> {{player.hydratedTeam.name}} </td>
        <td> <button class="small" (click)="handlePlayerSelected(player)"> Select </button> </td>
    </tr>
</table>
