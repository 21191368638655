<div class="teams-league-admin-container scrollable">
    <table>
        <tr>
            <th> # </th>
            <th> Team Name </th>
            <th> Trade Penalties </th>
            <th> Action </th>
        </tr>
        <tr *ngFor="let team of teams; let i = index">
            <td> {{i + 1}} </td>
            <td> {{team.name}}</td>
            <td> <input type="number" [(ngModel)]="team.numPenalties"> </td>
            <td> <button (click)="saveTeam(team)" class="small"> Save </button> </td>
        </tr>
    </table>
</div>