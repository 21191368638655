import { Injectable } from '@angular/core';
import { DataService, Player, Team, UpdateType } from '../data.service';

export interface HydratedPlayer extends Player {
  hydratedTeam: Team;
}

@Injectable({
  providedIn: 'root'
})
export class PlayerDataService {

  constructor(private _data: DataService) { }

  async getHydratedPlayer(playerId: string) {
    const player = await this._data.getDocument<Player>(`players/${playerId}`);
    return (await this.hydratePlayers([player]))[0];
  }

  async getHydratedPlayers() {
    const players = await this._data.getCollection<Player>('players');
    return await this.hydratePlayers(players);
  }

  async hydratePlayers(players: Player[]) {
    const teams = await this._data.getCollectionAsMap<Team>('teams');

    const hydratedPlayers = players.map((player) => {
      (player as HydratedPlayer).hydratedTeam = teams.get(player.team);
      return player;
    });
    return hydratedPlayers as HydratedPlayer[];
  }

  async createPlayer(username: string, email: string, name: string, authId: string) {
    const playerExists = await this.getPlayer(username);
    if(playerExists) {
      alert('Player already exists with this username.');
      return false;
    }
    const playerId = this.getUserIdFromUsername(username);
    const player = {
      email,
      username,
      name,
      team: 'Free Agent',
      authId,
      seasons: {},
    };
    await this._data.setDocument(`players/${playerId}`, player);
  }

  async getPlayerByAuthId(authId: string) {
    const player = await this._data.find<Player>('players', {
      authId,
    });
    return player;
  }

  async getPlayer(username: string) {
    const userId = this.getUserIdFromUsername(username);
    const user = await this._data.getDocument<Player>(`players/${userId}`);
    return user;
  }

  async updatePlayer(userId: string, update: any) {
    await this._data.updateDocument(`players/${userId}`, update);
  }

  async dropPlayer(playerId: string) {
    const players = await this._data.getCollectionAsMap<Player>('players');
    const player = players.get(playerId);
    if (!player) {
      console.warn('Tried to drop player that doesn\'t exist');
      return false;
    } else if (player.team === 'Free Agent') {
      console.warn('Tried to drop player that is already a Free Agent');
      return false;
    }
    const teamUpdate = {
      documentQuery: `teams/${player.team}`,
      update: {
        players: this._data.arrayRemove(playerId),
      },
      type: UpdateType.Update,
    };
    const playerUpdate = {
      documentQuery: `players/${playerId}`,
      update: {
        team: 'Free Agent',
      },
      type: UpdateType.Update,
    };
    await this._data.batchUpdate([teamUpdate, playerUpdate]);
  }

  private getUserIdFromUsername(username: string) {
    return username.toUpperCase();
  }
}
