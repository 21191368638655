import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService, NewsArticle } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss']
})
export class HomeViewComponent implements OnInit {
  title = "National Blitz League - Home";
  newsArticles: NewsArticle[];
  loaded = false;

  constructor(private _auth: AuthService, private _data: DataService) { }

  async ngOnInit() {
    const newsArticles = await this._data.getCollection<NewsArticle>('news');
    newsArticles.sort((articleA, articleB) => {
      return new Date(articleB.startTime).getTime() - new Date(articleA.startTime).getTime();
    });
    this.newsArticles = newsArticles;
    this.loaded = true;
  }

  getWinningTeamLogo() {
    const activeArticle = this.newsArticles[0];
    return `../../assets/images/logos/${activeArticle.data.winningTeamId}.png`;
  }

}
