import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { PlayerDataService } from 'src/app/services/data/player-data/player-data.service';
import { PromptService } from 'src/app/services/prompt/prompt.service';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss']
})
export class LoginViewComponent implements OnInit {
  emailIcon = faEnvelope;
  passwordIcon = faLock;
  fullNameIcon = faUser;
  loggingIn = true;

  constructor(
    private _auth: AuthService, 
    private _playerData: PlayerDataService, 
    private _prompt: PromptService, 
    private _appComponent: AppComponent,
    private _router: Router) { }

  ngOnInit(): void {

  }

  async login(email: string, password: string) {
    const authId = await this._auth.login(email, password);
    this._prompt.successAlert('Logged in successfully');
    await this._appComponent.initialize();
    this._router.navigate(['/']);
  }

  async registerUser(fullName: string, email: string, lichessUsername: string, password: string) {
    try {
      const currentPlayer = await this._playerData.getPlayer(lichessUsername);
      if (currentPlayer && currentPlayer.authId) {
        this._prompt.errorAlert('Player already registered with this username');
        return;
      }
      const authId = await this._auth.signup(email, password);
      if (currentPlayer) {
        // Player already exists but doesn't have an account. Update the player with registered information.
        await this._playerData.updatePlayer(currentPlayer.id, {
          authId,
          email,
          name: fullName,
        });
        // tslint:disable-next-line: max-line-length
        this._prompt.successAlert(`Sucessfully created account. This account has been attached to existing player ${currentPlayer.username}.`);
      } else {
        await this._playerData.createPlayer(lichessUsername, email, fullName, authId);
        this._prompt.successAlert(`Successfully registered for the National Blitz League.`);
      }
      await this._appComponent.initialize();
      this._router.navigate(['/']);
    } catch (err) {
      this._prompt.errorAlert(err.message);
    }
  }

  async sendForgotPasswordEmail() {
    try {
      const email = await this._prompt.prompt('Enter email');
      if (!email) { return; }
      await this._auth.sendForgotPasswordEmail(email);
      this._prompt.successAlert(`Reset password instructions sent to ${email}`);
    } catch (err) {
      this._prompt.errorAlert('Something went wrong. Did you enter the right email?');
    }
  }

  async switchToSignup() {
    this.loggingIn = false;
  }

  async switchToLogin() {
    this.loggingIn = true;
  }

}
