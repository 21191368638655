import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NavComponent } from './modules/awesome-ui/nav/nav.component';
import { AuthService } from './services/auth/auth.service';
import { PromptService } from './services/prompt/prompt.service';
import { PlayerDataService } from './services/data/player-data/player-data.service';
import {environment} from '../environments/environment';
import { Player } from './services/data/data.service';

type RouteCB = () => any;
type NavItemRoute = string | RouteCB;
interface NavItem {
  route: NavItemRoute;
  displayName: string;
  icon: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('uiNav') uiNav: NavComponent;
  title = 'angular-firebase-template';
  activeRoute: string = '';
  navItems: NavItem[] = [];
  mobileNavOpened = false;
  isDev = Boolean(!environment.production);

  constructor(private _router: Router, private _auth: AuthService, private _prompt: PromptService, private _playerData: PlayerDataService) {
    this.initialize();
  }

  async initialize() {
    this.navItems = [
      {
        route: '',
        displayName: 'Home',
        icon: 'home',
      },
      {
        route: 'standings',
        displayName: 'Standings',
        icon: 'medal',
      },
      {
        route: 'schedule',
        displayName: 'Schedule',
        icon: 'calendar-alt',
      },
      {
        route: 'player-stats',
        displayName: 'Player Stats',
        icon: 'user',
      }
    ];
    const activeUserAuthId = await this._auth.getActiveUser();
    let activePlayer: Player|undefined ;
    if(activeUserAuthId) {
      activePlayer = await this._playerData.getPlayerByAuthId(activeUserAuthId);
    } 

    if(activePlayer) {
      this.navItems.push({
        route: `admin/player/${activePlayer.id}`,
        displayName: 'My Profile',
        icon: 'user',
      });
      if(activePlayer.role?.admin || activePlayer.role?.teamOwner) {
        this.navItems.push({
          route: 'admin',
          displayName: 'Manage',
          icon: 'user',
        });
      }
    } else {
      this.navItems.push({
        route: 'login',
        displayName: 'Sign Up',
        icon: 'user',
      });
    }
  }

  handleMobileNavOpened() {
    this.mobileNavOpened = true;
  }
  handleMobileNavClosed() {
    this.mobileNavOpened = false;
  }

  handleRouteClick(route: NavItemRoute) {
    if(typeof route === 'string') {
      if(this.uiNav) {
        this.uiNav.close();
      }
      this._router.navigate([route]);
      this.activeRoute = route;
    } else {
      route();
    }
  }
}
