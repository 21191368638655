import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { PlayerDataService, HydratedPlayer } from 'src/app/services/data/player-data/player-data.service';

@Component({
  selector: 'app-players-table',
  templateUrl: './players-table.component.html',
  styleUrls: ['./players-table.component.scss']
})
export class PlayersTableComponent implements OnInit {
  loaded = false;
  players: HydratedPlayer[];
  @Input() filterFunction?: (HydratedPlayer) => boolean;
  @Output() playerSelected = new EventEmitter<HydratedPlayer>();

  constructor(private _playerData: PlayerDataService) { }

  async ngOnInit() {
    this.players = await this._playerData.getHydratedPlayers();
    if(this.filterFunction) {
      this.players = this.players.filter(this.filterFunction);
    }
    this.loaded = true;
  }

  handlePlayerSelected(player: HydratedPlayer) {
    this.playerSelected.emit(player);
  }

}
