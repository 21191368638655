import { Injectable } from '@angular/core';
import { UtilitiesService } from '../utilities/utilities.service';

export interface ILichessResult {
  rank: number;
  score: number;
  rating: number;
  username: string;
  title: string;
  performance: number;
}

export interface INewLichessTournament {
  // The tournament name. Leave empty to get a random Grandmaster name
  name?: string;
  // number [ 0 .. 60 ] Clock initial time in minutes
  clockTime: number;
  // integer [ 0 .. 360 ] Clock increment in seconds
  clockIncrement: number;
  // integer [ 0 .. 360 ] How long the tournament lasts, in minutes
  minutes: number;
  waitMinutes?: number;
  startDate?: number;
  variant?: string;
  rated?: boolean;
  position?: string;
  berserkable?: boolean;
  password?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LichessService {
  // TODO(augerm): This should be secured... but I don't really care if people create two tournaments a day for me...
  apiToken: string = 'd3Qr7QzQjGcCjnmD';
  constructor(private utilities: UtilitiesService) { }
  async getResults(tournamentId: string) {
      let resultsNdJson: Array<any> = await this.utilities.sendRequest(`https://lichess.org//api/tournament/${tournamentId}/results`);
      if(!resultsNdJson.length) { return null; }
      let resultsArr: Array<ILichessResult> = this.utilities.parseResults(resultsNdJson);
      return resultsArr;
  }

  createTournament(tournament: INewLichessTournament) {
    return this.utilities.sendPostRequestWithFormData('https://lichess.org/api/tournament', tournament, this.apiToken);
  }
}