<ng-container *ngIf="loaded && isDesktop">
    <div class="desktop-container">
        <div class="admin-container">
            <div class="left-panel">
                <ng-container [ngTemplateOutlet]="mainContent"></ng-container>
            </div>
            <div class="right-panel">
                <ng-container [ngTemplateOutlet]="playerPicture"></ng-container>
            </div>
        </div>
        <div class="bottom-panel">
            <ng-container [ngTemplateOutlet]="logoutBtn"></ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="loaded && !isDesktop">
    <div class="mobile-container">
        <ng-container [ngTemplateOutlet]="playerPicture"></ng-container>
        <ng-container [ngTemplateOutlet]="mainContent"></ng-container>
        <ng-container [ngTemplateOutlet]="logoutBtn"></ng-container>
    </div>
</ng-container>

<ng-template #mainContent>
    <div class="player-name">
        {{player.name}}
    </div>
    <div class="username">
        {{player.username}}
    </div>

    <div class="player-stats">
        <div class="bullet-ppa">
            <div class="ppa-headline"> Bullet PPA </div>
            <div class="ppa"> {{player.seasons[activeSeason] ? player.seasons[activeSeason].bulletStats.ppg : 'N/A'}} </div>
        </div>
        <div class="blitz-ppa">
            <div class="ppa-headline"> Blitz PPA </div>
            <div class="ppa"> {{player.seasons[activeSeason] ? player.seasons[activeSeason].blitzStats.ppg : 'N/A'}} </div>
        </div>
    </div>
</ng-template>

<ng-template #logoutBtn>
    <div class="logout-container" *ngIf="isMyAccount">
        <button class="medium" (click)="logout()"> Log Out </button>
    </div>
</ng-template>

<ng-template #playerPicture>
    <img class="player-picture" src="../../../../assets/images/avatar.png">
</ng-template>