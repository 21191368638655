import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableComponent } from './table/table.component';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  declarations: [
      SidebarComponent,
      NavComponent,
      TableComponent,
      ModalComponent
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
  ],
  exports: [SidebarComponent, NavComponent, TableComponent, FontAwesomeModule, ModalComponent]
})
export class AwesomeUiModule {}
