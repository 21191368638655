import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export const Endpoints = {
  mainHTTPSJob: `${environment.functionsEndpoint}/mainHTTPSJob`,
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private _http: HttpClient) { }

  get(endpoint: string) {
    return this._http.get(endpoint).toPromise();
  }
}
