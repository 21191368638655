<ng-container *ngIf="navOpen">
    <div #awesomeUiSidebar>
        <awesome-ui-sidebar [customStyles]="sidebarStyles">
            <ng-content></ng-content>
        </awesome-ui-sidebar>
    </div>
    
</ng-container>
<ng-container *ngIf="!navOpen">
    <div class="nav-bar-toggle" (click)="openNav()">
        <fa-icon [icon]="faBars"></fa-icon>
    </div>
</ng-container>


