import { Injectable } from '@angular/core';
import { DataService, Season, Tournament, Team } from '../data.service';

export interface HydratedSeason extends Season {
  hydratedTournaments: Array<Tournament>;
  hydratedTeams: Array<Team>;
}
export interface SeasonData {
  activeSeason: string;
}

@Injectable({
  providedIn: 'root'
})
export class SeasonDataService {

  constructor(private _data: DataService) { }

  async getHydratedSeasons() {
    const tournaments = await this._data.getCollectionAsMap<Tournament>('tournaments');
    const teams = await this._data.getCollectionAsMap<Team>('teams');
    const seasons = await this._data.getCollection<Season>('seasons');

    const hydratedSeasons = seasons.map((season) => {
      const hydratedSeason: HydratedSeason = {
        ...season,
        hydratedTournaments: [],
        hydratedTeams: [],
      };
      for (const tournament of season.tournaments) {
        hydratedSeason.hydratedTournaments.push(tournaments.get(tournament));
      }
      for(const team of season.teams) {
        hydratedSeason.hydratedTeams.push(teams.get(team));
      }
      return hydratedSeason;
    });
    return hydratedSeasons;
  }

  async getActiveSeason() {
    const seasonData = await this._data.getDocument<SeasonData>('leaguemetadata/seasons');
    return Number(seasonData.activeSeason);
  }
}
