import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TeamDataService, HydratedTeam } from 'src/app/services/data/team-data/team-data.service';
import { PlayerDataService, HydratedPlayer } from 'src/app/services/data/player-data/player-data.service';

@Component({
  selector: 'app-team-admin-view',
  templateUrl: './team-admin-view.component.html',
  styleUrls: ['./team-admin-view.component.scss']
})
export class TeamAdminViewComponent implements OnInit {
  loaded = false;
  routerSub: Subscription;
  teamId: string;
  constructor(private route: ActivatedRoute, private _teamData: TeamDataService, private _playerData: PlayerDataService) { }

  async ngOnInit() {
    this.routerSub = this.route.params.subscribe(async (params) => {
      this.loaded = false;
      this.teamId = params.teamId;
      this.loaded = true;
    });
  }
}
