import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { PlayerDataService } from '../data/player-data/player-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState: firebase.User;

  constructor(private _auth: AngularFireAuth, private _player: PlayerDataService) {}

  async signup(email: string, password: string) {
    const response = await this._auth.createUserWithEmailAndPassword(email, password);
    return response.user.uid;
  }

  login(email: string, password: string) {
    return this._auth.signInWithEmailAndPassword(email, password);
  }

  async getActiveUser() {
    const authState = await this._getAuthState();
    return authState?.uid;
  }

  logout() {
    return this._auth.signOut();
  }

  isAuthenticated() {
    return Boolean(this._auth.user);
  }

  async getMyPlayer() {
    const activeAuthId = await this.getActiveUser();
    if (!activeAuthId) { return null; }
    const myPlayer = await this._player.getPlayerByAuthId(activeAuthId);
    return myPlayer;
  }

  async sendForgotPasswordEmail(email) {
    return this._auth.sendPasswordResetEmail(email);
  }

  async confirmPasswordReset(code: string, newPassword: string) {
    return this._auth.confirmPasswordReset(code, newPassword);
  }

  private _getAuthState(): Promise<firebase.User> {
    const promise = new Promise((resolve) => {
      this._auth.authState.subscribe(authState => {
        this.authState = authState;
        resolve(this.authState);
      });
    });
    return promise as Promise<firebase.User>;
  }
}
