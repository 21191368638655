import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'awesome-ui-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Output() navOpened = new EventEmitter();
  @Output() navClosed = new EventEmitter();
  sidebarStyles = {
    width: '100vw',
    'background-color': '#212830',
  };
  @ViewChild('awesomeUiSidebar') awesomeUiSidebar: ElementRef;
  navOpen = false;
  faBars = faBars;
  boundDismissListener: (event: Event) => void;

  constructor() {
    this.boundDismissListener = (event: Event) => {
      this.dismissListener(event);
    };
  }

  ngOnInit(): void {
  }

  openNav() {
    if (this.navOpen === true) { return; }
    this.navOpen = true;
    this.addDismissListener();
    this.navOpened.emit();
  }

  addDismissListener() {
    window.addEventListener('click', this.boundDismissListener, true);
  }

  dismissListener(event: Event) {
    if (event.target !== this.awesomeUiSidebar.nativeElement && !this.awesomeUiSidebar.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  close() {
    this.navOpen = false;
    window.removeEventListener('click', this.boundDismissListener, true);
    this.navClosed.emit();
  }

}
