<div id="app-header">
    <div id="nav">
        <ul>
            <li> <img class="nbl-logo" src="../assets/images/NBL-Logo.png"></li>
            <li *ngFor="let navItem of navItems" [class.active]="navItem.route === activeRoute" (click)="handleRouteClick(navItem.route)">
                <a [routerLink]="navItem.route"> {{navItem.displayName}} </a>
            </li>
            <li [class.active]="activeRoute === 'watch-now'" (click)="handleRouteClick('watch-now')">
                <a>
                    <button class="watch-now-button"> Watch Now &nbsp; <i class="fas fa-tv"></i> </button>
                </a>
            </li>
        </ul>
    </div>
    <div id="mobile-nav">
        <awesome-ui-nav #uiNav (navOpened)="handleMobileNavOpened()" (navClosed)="handleMobileNavClosed()">
            <div id="close-icon" (click)="uiNav.close()"> X </div>
            <ul>
                <li> <img class="nbl-logo" src="../assets/images/NBL-Logo.png"></li>
                <li *ngFor="let navItem of navItems" (click)="handleRouteClick(navItem.route)">
                    <div class="nav-item">
                        <i [class]="'fas fa-' + navItem.icon"></i> <br>
                        <a> {{navItem.displayName}} </a>
                    </div>
                </li>
                <li class="watch-now-tile-container" (click)="handleRouteClick('watch-now')"> 
                    <div class="nav-item">
                        <a>
                            <i class="fas fa-tv"></i> <br>
                            Watch Now
                        </a>
                    </div>
                </li>
            </ul>
        </awesome-ui-nav>
        <div class="nbl-logo-mobile-container" *ngIf="!mobileNavOpened"> 
            <img class="nbl-logo" src="../assets/images/NBL-Logo.png"> 
        </div>
    </div>
</div>
<div id="app-content">
    <div class="side-column">
        <img src="./assets/images/background-image/Left-Hand-Side-Logos.png">
    </div>
    <div class="center-column">
        <router-outlet *ngIf="!mobileNavOpened"></router-outlet>
    </div>
    <div class="side-column">
        <img src="./assets/images/background-image/Right-Hand-Side-Logos.png">
    </div>
</div>
<div id="environment-description" *ngIf="isDev">
    DEV
</div>
