import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private http: HttpClient) { }
  lowerCaseTeamsPlayers(teams) {
      for(let team of teams) {
        for(let i = 0; i < team.players.length; i++) {
          team.players[i] = team.players[i].toLowerCase();
        }
      }
      return teams;
  }
  sanitizeInput(input) {
    return input.trim();
  }
  sendRequest(endpoint): Promise<any> {
    const promise = new Promise((resolve, reject) => {
        const oReq = new XMLHttpRequest();
        oReq.onreadystatechange = function() {
            if (oReq.readyState === 4) {
                resolve(oReq.response);
            }
        }
        oReq.open("GET", endpoint);
        oReq.send();
    });
    
    return promise;
  }
  sendPostRequestWithFormData(endpoint: string, data: any, authorizationToken?: string) {
    let params = new URLSearchParams("");
    for(let key in data) {
      if(data.hasOwnProperty(key)) {
        params.append(key, data[key]);
      }
    }
    if(authorizationToken) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${authorizationToken}`
        })
      };
      return this.http.post(endpoint, params.toString(), httpOptions);
    }
    return this.http.post(endpoint, params.toString());
  }
  async getJSON(endpoint) {
    let json = await this.sendRequest(endpoint);
    return JSON.parse(json);
  }
  parseResults(ndJSON) {
    let jsonArr = ndJSON.split('\n');
    jsonArr.pop();
    let resultsArr = [];
    for(let json of jsonArr) {
        resultsArr.push(JSON.parse(json));
    }
    return resultsArr;
  }
  // Return date obj in format "6-19-2019"
  getDateStr(curDate: Date) {
    return curDate.toLocaleDateString().replace(/\//g, "-");
  }
  // Return current date in format "6-19-2019"
  getNowDateStr() {
    return new Date().toLocaleDateString().replace(/\//g, "-");
  }
  // This handles the weird behavior where new Date("2019-06-26") gives you the day before.
  // Adding a timezone at the end resolves this problem.
  // https://stackoverflow.com/a/7556642/5100560
  getDateFromStr(dateStr: string) {
    return new Date(dateStr + " CST");
  }
  getFormattedDateStr(dateStr: string) {
    let date = this.getDateFromStr(dateStr);
    return this.getDateStr(date);
  }

  /* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#Getting_a_random_integer_between_two_values_inclusive */
  getRandomIntInclusiveBetween(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}