import { Component, OnInit } from '@angular/core';
import { DataService, TVData, Streamer } from 'src/app/services/data/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-watch-now-view',
  templateUrl: './watch-now-view.component.html',
  styleUrls: ['./watch-now-view.component.scss']
})
export class WatchNowViewComponent implements OnInit {
  channels: Streamer[];
  activeChannel: Streamer;
  loaded = false;
  constructor(private _data: DataService, private _sanitizer: DomSanitizer, private _router: Router) { }

  async ngOnInit() {
    const tv = await this._data.getDocument<TVData>('leaguemetadata/tv');
    this.channels = tv.streamers;
    for(const channel of this.channels) {
      if(channel.liveStream) {
        this.activeChannel = channel;
        break;
      }
    }
    this.channels.sort((channelA, channelB) => {
      if(channelA.liveStream) {
        return -1;
      } else if(channelB.liveStream) {
        return 1;
      } else {
        return 1;
      }
    });
    this.loaded = true;
  }

  getTwitchChannel() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(`https://player.twitch.tv/?channel=${this.activeChannel.username}`);
  }

  getTwitchChat() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.twitch.tv/embed/${this.activeChannel.username}/chat`);
  }

  changeChannel(channel: Streamer) {
    this.activeChannel = channel;
  }

  exitWatchNow() {
    this._router.navigateByUrl('');
  }
}
