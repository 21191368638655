<ng-container *ngIf="loaded">
    <div class="schedule-view-container" class="panel-container">
        <div class="schedule-container" class="panel">
            <table>
                <tr>
                    <th> Week # </th>
                    <th> Date </th>
                    <th> Time </th>
                    <th> Arena Type </th>
                    <!-- <th> Broadcast Link </th> -->
                    <!-- <th> Commentator </th> -->
                </tr>
                <tr *ngFor="let scheduleItem of schedule; let i = index">
                    <td> {{ i + 1 }} </td>
                    <td> {{ scheduleItem.date }} </td>
                    <td> {{ scheduleItem.time }} </td>
                    <td> {{ scheduleItem.timeControl }} </td>
                    <!-- <td> 
                        <a [href]="scheduleItem.streamLink">
                            <button class="small" *ngIf="scheduleItem.rawDate < now()"> Watch Now &nbsp; <i class="fas fa-tv"></i> </button>
                        </a>
                    </td>
                    <td> {{ scheduleItem.commentator }} </td> -->
                </tr>
            </table>
        </div>
    </div>
    
</ng-container>
