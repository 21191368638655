import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'awesome-ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() handleClose: () => {};

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.handleClose();
  }

}
