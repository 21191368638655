<ng-container *ngIf="loaded">
    <div class="panel-container">
        <div class="panel">
            <img class="winning-team-logo" [src]="getWinningTeamLogo()">
            <p class="content-description"> 
                {{newsArticles[0].content}}
            </p>
            <a 
                [href]="'https://lichess.org/tournament/' + newsArticles[0].id"
                target="_blank">
                <button> Full Results </button>
            </a>
        </div>
    </div>
</ng-container>
