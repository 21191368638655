<div class="background-blur"></div>
<div class="modal-container">
    <div (click)="close()" class="close-icon">
        X
    </div>
    <div class="modal-header">
        <ng-content select=".header"></ng-content>
    </div> <br>
    <div class="modal-content">
        <ng-content></ng-content>
    </div>
</div>
