import { Component, OnInit } from '@angular/core';
import { PlayerDataService, HydratedPlayer } from 'src/app/services/data/player-data/player-data.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PromptService } from 'src/app/services/prompt/prompt.service';
import { AppComponent } from 'src/app/app.component';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { SeasonDataService } from 'src/app/services/data/season-data/season-data.service';

@Component({
  selector: 'app-player-admin-view',
  templateUrl: './player-admin-view.component.html',
  styleUrls: ['./player-admin-view.component.scss']
})
export class PlayerAdminViewComponent implements OnInit {
  player: HydratedPlayer;
  playerId: string;
  routerSub: Subscription;
  loaded = false;
  userAvatar = faUser;
  activeSeason: number;
  isMyAccount = false;
  isDesktop = window.innerWidth > 649;

  constructor(private _playerData: PlayerDataService,
              private _route: ActivatedRoute,
              private _auth: AuthService,
              private _prompt: PromptService,
              private _appComponent: AppComponent,
              private _seasonData: SeasonDataService,
              private _router: Router) { }

  async ngOnInit() {
    this.routerSub = this._route.params.subscribe(async (params) => {
      this.loaded = false;
      this.playerId = params.playerId;
      await this.renderPlayerData();
      this.activeSeason = await this._seasonData.getActiveSeason();
      await this.renderAdminView();
      this.loaded = true;
    });
  }

  async logout() {
    try {
      await this._auth.logout();
      this._prompt.successAlert('Successfully logged out.');
      await this._appComponent.initialize();
      await this.renderAdminView();
      this._router.navigate(['/']);
    } catch (err) {
      this._prompt.unknownErrorAlert();
    }
  }

  async renderPlayerData() {
    this.player = await this._playerData.getHydratedPlayer(this.playerId);
    this.loaded = true;
  }

  async renderAdminView() {
    const myPlayer = await this._auth.getMyPlayer();
    if (!myPlayer) {
      this.isMyAccount = false;
    } else if (myPlayer.id === this.player.id) {
      this.isMyAccount = true;
    } else {
      this.isMyAccount = false;
    }
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

}
